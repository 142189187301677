// === packages
import { Fragment, useEffect, useState} from "react";
// === modules
import BtnGen from './../layout/buttons/generalButton'
// === css
import Styles_hp from './../css-modules/homepage.module.css'
import Styles_brands from './../css-modules/brands.module.css'
// === images
import img01 from './../../asset/images/homepage/elderly-02.webp'
import img02 from './../../asset/images/homepage/elderly-03.webp'
import img03 from './../../asset/images/homepage/HNF_kinesim.webp'
// === svg
// === handler


function HPIntro (props){
  // States
    const [error, setError] = useState(false)
    // Effects
    // handler

    return(
      <div className={Styles_brands.brands__section}>
      <div className={Styles_brands.brands__section__container}>
      <div className={Styles_hp.homepage__section}>
        <div className={Styles_hp.homepage__section__container}>
          <div className={Styles_hp.homepage__section__container__grid3}>
            <div className={Styles_hp.homepage__section__container__grid3__item}>
              <h3>Empower</h3>
              <div className={Styles_hp.homepage__section__container__grid3__item__imgWrapper}>
                <img src = {img01} alt = ""></img>
              </div>
              <p>Our solution will make hospital grade rehabilitation available to any clinic and eldercare in the community. We will equip your therapists with the best tools to make an impact.</p>
            </div>
            <div className={Styles_hp.homepage__section__container__grid3__item}>
              <h3>Engage</h3>
              <div className={Styles_hp.homepage__section__container__grid3__item__imgWrapper}>
              <img src = {img02} alt = ""></img>
              </div>
              <p>We improve patient participation and engagement by innovative technologies, such as tele-rehabilitation, VR and proprioception immersion.</p>

            </div>
            <div className={Styles_hp.homepage__section__container__grid3__item}>
              <h3>Knowledge</h3>
              <div className={Styles_hp.homepage__section__container__grid3__item__imgWrapper}>
              <img src = {img03} alt = ""></img>
              </div>
              <p>Our team has over 10 years of experience in the field of rehab-technology. We compare and provide a solution that is best suited for your spectacular goals.</p>
            </div>
          </div>
        </div>
      </div>
      <div className={Styles_hp.homepage__section}>
        <div className={Styles_hp.homepage__section__container}>
          <BtnGen href = "/brands">Our solutions</BtnGen>
          </div>
        </div>
      </div>
      </div>
      
    )
}

export default HPIntro;
