// === packages
import React from 'react';
import {useState, useEffect} from 'react'
// === modules
import FooterBrands from '../layout/footerBrands';
import FooterSocial from '../layout/footerSocial';
import FooterContact from '../layout/footerContact';
// === handlers
// === css 
import styles from "./../css-modules/sections.module.css"
// === import images
import mainLogo from './../../asset/icons/sankhz_logo_x500.png'

function Footer(props){
    return (
      <React.Fragment>
        <div className={styles.site_footer}>
          <div className={styles.site_footer_container}>
            <div className={styles.site_footer_container_block}>
              <img
                className={styles.site_footer_container_block_mainLogo}
                src={mainLogo}
                alt="sankhz_main_logo"
              ></img>
              <p className={styles.site_footer__credits}>
                “ANKHZ” is the key of life in ancient Egyptian. Combining with
                the initials of our founders, SANKHZ aims at enabling therapists
                and patients to unlock their full potential.
              </p>
            </div>
            <div className={styles.site_footer_container_grid}>
              <div className={styles.site_footer_container_grid_block}>
                <h3 className={styles.site_footer_container_block_h3}>Brand</h3>
                <FooterBrands></FooterBrands>
              </div>
              <div className={styles.site_footer_container_grid_block}>
                <h3 className={styles.site_footer_container_block_h3}>
                  Engage
                </h3>
                <FooterSocial></FooterSocial>
              </div>
              <div className={styles.site_footer_container_grid_block}>
                <h3 className={styles.site_footer_container_block_h3}>
                  Contact us
                  <FooterContact></FooterContact>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
}

export default Footer;