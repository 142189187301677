// === packages
import { Fragment, useEffect, useState} from "react";
import { useParams } from "react-router-dom";
// === css modules
import Styles from "./../components/css-modules/brands.module.css"
// === components
import Body from '../components/sections/body'
import AboutContent from "../components/layout/aboutContent";
import Social from "../components/layout/social";
// === handler
import getProduct from "../apiCalls/getProduct";

function AboutPage (props){
    // params
    let params = useParams();
    // states
    const [product, setProduct] = useState(false)
    const [error, setError] = useState(false)

    // effect
    useEffect(()=>{
        // console.log(params.productId)
        async function fetchData() {
            try {
              const res = await getProduct(params.productId)
              setProduct(res.data)
              console.log(res)
            } catch (err) {
                console.log("err caught by component")
                setError(()=>{throw new Error("blah")})
            }
          }
          fetchData();
    },[])
    // handlers
    
    return (
        <Fragment>
        <Body color = '#fff'>
            <div className={Styles.brands__section}>
            <div className={Styles.brands__section__container}>
                <h1 className={Styles.brands__section__container__h1}>About us</h1>
                <p className={Styles.brands__section__container__p}>We bring the best global innovations to the scene of Singapore community rehabilitation.</p>
                    <AboutContent/>
                    <Social/>
                    
                </div>
                </div>
        </Body>
        </Fragment>
    )
}

export default AboutPage;