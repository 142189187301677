// === packages
import { Fragment, useEffect, useState} from "react";
// === modules
import ProductTabs from "./productTabs";
// === css
import Styles from './../css-modules/products.module.css'
// === handler
// === variables
const tabsContent = {
  details:"this is the spec of this stuff",
  video: [`<iframe width="100%" height="315" src="https://www.youtube.com/embed/e3jur9czRu8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`]
}


function ProductContent (props){
    const [error, setError] = useState(false)
    const [html, setHtml] = useState(false)
    const [content, setContent] = useState(false)
    const [tabsContent, setTabsContent] = useState(false)
    const [description, setDescription] = useState(false)




    // Effects
    useEffect(()=>{
      if(props.product){
        // console.log(props.product.attributes.html_body_00)
        // console.log(props.product.attributes.html_body_01)
        // console.log(props.product.attributes.content)
        // console.log(props.product.attributes.description)

        setHtml({
          body_00:props.product.attributes.html_body_00,
          body_01:props.product.attributes.html_body_01 
        })
        setContent((props.product.attributes.content))
        setDescription(props.product.attributes.description)
        setTabsContent({
          specification:props.product.attributes.specification,
          video:props.product.attributes.videoSrc,
        })
      }
    },[props])
    // useEffect(()=>{console.log((JSON.stringify(content)))},[content])

    // handler
    return(
        <Fragment>
          <div className={Styles.product__grid__content__description}>
            <h3>Introduction</h3>
            <p>{description?description:""}</p>
          </div>
          <div className={Styles.product__grid__content__content}>
            <h3>Description</h3>
           {content?content.split("\n").map((el, ind)=>{return <p key = {`content__P${ind}`}>{el}</p>}):""}
          </div>
          <ProductTabs tabsContent = {tabsContent}></ProductTabs>

          <div><p>{html.body_00?html.body_00:""}</p></div>
          <div><p>{html.body_01?html.body_01:""}</p></div>
        </Fragment>
    )
}

export default ProductContent;
