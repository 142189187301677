// === packages
import { Fragment, useState} from "react";
// === components
import Body from '../components/sections/body'
import Brands from "../components/layout/brands"

// === images
// import bg02 from "../asset/images/homepage/intersection12.jpg"

function BrandsPage (props){
    // states
    // effect
    // handlers
    
    return (
        <Fragment>
        <Body color = '#fff'>
            <Brands/>
        </Body>
        </Fragment>
    )
}

export default BrandsPage;