// === packages
import React from 'react'
// === components
import MainSlideStatic from '../components/layout/mainSlideStatic';
import Body from './../components/sections/body'
import Intersection from "../components/layout/intersection";
import HPIntro from '../components/layout/homePageIntro';

// === images

// === variables

function HomePage (){
    return (
        <React.Fragment>
            {/* <ErrorBoundary>
                <BrokenStuff></BrokenStuff>
            </ErrorBoundary> */}
        <Body>
            <MainSlideStatic/>
        </Body>
            <Intersection color = "rgb(232 232 232)" title = "Where passion meets innovation" 
            message = "Let SANKHZ be your partner to unlock the limitless potential of recovery in our community"></Intersection>
        <Body >
            <HPIntro></HPIntro>
            {/* <MessageBoard/> */}
        </Body>
        </React.Fragment>
    )
}

export default HomePage;