// === packages
import { useEffect, useState} from "react";
// === css
import Styles from './../../css-modules/animation.module.css'



function FlyInText (props){
    return (
        <div className={Styles.FlyInText} style = {{top: "0px"}}>{props.children}</div>
    )
}


export default FlyInText