const defaultUrl = "https://backendstrapi-dot-strapi-sankhz.as.r.appspot.com/api/products"
// const defaultUrl = "https://strapi-sankhz.as.r.appspot.com/api/brands?populate=*"
// const filterUrl = "https://strapi-sankhz.as.r.appspot.com/api/products?filters[title][$eq]=KINESIM"

const getProduct = async function(productId=""){
  try{
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    const res = await fetch(`${defaultUrl}/${productId}?populate=*`, requestOptions)
    const result = await res.text()
    const data = await JSON.parse(result)
    return data
  }catch(err){console.log(err)}
}

export default getProduct
