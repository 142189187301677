// === packages
import {useState, Fragment, memo} from 'react';
import { Link } from "react-router-dom";

// === components
import NavBar from '../functional/navBar';
import Hamburger from '../layout/hamburger';
import HamburgerMenu from '../layout/hamburger__menu';
// === css
import styles from './../css-modules/sections.module.css'
// === image
import mainLogo from './../../asset/icons/sankhz_logo_x500.png'

// === variables
const navList = [
  { name: "about us", href: "about" },
  { name: "brands", href: "brands" },
  { name: "contact", href: "contact" },
];


function Header(){
    // === state
    const [isOpened, setIsOpened] = useState(false)
    const [hamState, setHamState] = useState({
        hamScale: '50%',
        menuHeight: '0px'
    })
    return (
       <Fragment>
            <div className={styles.site_header}>
                <div className={styles.site_header_container}>
                <Link to={'/'}>
                    <img className={styles.site_header_container__logo} src = {mainLogo} alt = "sankhz_main_logo"></img>
                </Link>
                <NavBar  navList ={navList} ></NavBar>
                <Hamburger setIsOpened = {setIsOpened} setHamState = {setHamState} isOpened = {isOpened} hamState = {hamState}  ></Hamburger>
                </div>
            </div>
            <HamburgerMenu setIsOpened = {setIsOpened} hamState = {hamState} navList ={navList}></HamburgerMenu>
        </Fragment>
  
    )
}

export default memo(Header);