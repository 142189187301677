// === packages
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope, faAddressBook } from '@fortawesome/free-solid-svg-icons'
// === handlers
// === modules
// === css 
import styles from "./../css-modules/sections.module.css"



function FooterContact(props){
    
    return (
      <React.Fragment>
        <div className={styles.site_footer_container_grid_link}>
        <FontAwesomeIcon icon={faEnvelope} />
                      <span>
                        sales@sankhz.com
                      </span>
        </div>
        <div className={styles.site_footer_container_grid_link}>
        <FontAwesomeIcon icon={faPhone} />
                      <span>
                        +65 8876 2060
                      </span>
        </div>
        <div className={styles.site_footer_container_grid_link}>
        <FontAwesomeIcon icon={faAddressBook} />
                      <span>
                        SANKHZ Pte Ltd #13-76, Wood Square Tower 1, 12 Woodlands Square, Singapore 737715
                      </span>
        </div>

            
      </React.Fragment>
    );
}

export default FooterContact;