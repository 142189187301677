// === packages
import { Fragment, useEffect, useState} from "react";
import { useParams } from "react-router-dom";
// === css modules
import Styles from "./../components/css-modules/brands.module.css"
// === components
import Body from '../components/sections/body'
import ContactForm from '../components/layout/contactForm'
// === handler

function ContactPage (props){
    // params
    // states
    // effect
    // handlers
    
    return (
        <Fragment>
        <Body color = '#fff'>
            <div className={Styles.brands__section}>
            <div className={Styles.brands__section__container}>
                <h1 className={Styles.brands__section__container__h1}>Contact us</h1>
                <p className={Styles.brands__section__container__p}>We would love to connect with you,  hope that our information will help to spark some ideas for you and your teams.</p>
                    <ContactForm/>
                </div>
                </div>
        </Body>
        </Fragment>
    )
}

export default ContactPage;