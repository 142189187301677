// === packages
import { Fragment, useState} from "react";
import { useParams } from "react-router-dom";
// === css modules
import Styles from "./../components/css-modules/brands.module.css"
// === components
import Body from '../components/sections/body'
import ProductsList from "../components/layout/productsList"
// === handler
// === images

function BrandPage (props){
    // states
    // effect
    // handlers
    // params
    let params = useParams();
    
    return (
        <Fragment>
        <Body color = '#fff'>
            <ProductsList id = {params.brandId}/>
        </Body>
        </Fragment>
    )
}

export default BrandPage;