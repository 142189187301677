// === packages
import { Fragment, useEffect, useState} from "react";
// === css
import Styles from './../css-modules/products.module.css'
// === handler


function ProductContent (props){
    const [error, setError] = useState(false)
    const [tabsContent, setTabsContent] = useState(false)
    const [currentTab, setCurrentTab] = useState(0)


    // Effects
    useEffect(()=>{
      if(props.tabsContent){
        setTabsContent(Object.entries(props.tabsContent))
      }
    },[props])
    // change tab
    useEffect(()=>{
      // console.log(tabsContent[currentTab][1])
    },[currentTab])


    // handler
    const tabClickHanlder = (ind)=>{
      setCurrentTab(ind)
    }

    return(
        <Fragment>
          <div className={Styles.tagsHolder}>
            {tabsContent? tabsContent.map((el, ind)=>{
              return (
                <div onClick = {()=>{tabClickHanlder(ind)}} className={currentTab === ind? Styles.tagsHolder__tag__active:Styles.tagsHolder__tag} key = {`tab_${ind}`}>
                  {el[0]}
                  </div>
              )
            }):""}
          </div>
          <div className={Styles.tagContentHolder}>
            {(()=>{
              if(currentTab === 0&&tabsContent){
                return (
                  tabsContent[0][1].split("\n").map((el, ind)=>{return <p className="tabsContent__content" key = {`tabsContent__P${ind}`}>{el}</p>})
                )
              }
              if(currentTab === 1&&tabsContent){
                return (
                  <iframe width="100%" height="315" src={tabsContent[1][1]} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                )
              }
            })()}
          </div>
        </Fragment>
    )
}

export default ProductContent;
