// === packages
import { useEffect, useState} from "react";
import {Outlet,Link} from "react-router-dom";


// === css
import Styles from './../css-modules/brands.module.css'
// === handler
import getBrand from '../../apiCalls/getBrand';
import getProduct from "../../apiCalls/getProduct";

function ProductsList (props){
    const [error, setError] = useState(false)
    const [brand, setBrand] = useState(false)
    const [products, setProducts] = useState([])

    // Effects
    useEffect(() => {
        async function fetchData() {
          try {
            const res = await getBrand(props.id)
            setBrand(res.data)
            // console.log(res)
          } catch (err) {
              console.log("err caught by component")
              setError(()=>{throw new Error("blah")})
          }
        }
        fetchData();
      }, []);
      useEffect(() => {
        if(brand){
          // console.log(brand)
          brand.attributes.products.data.forEach((el) => {
            // console.log(el.id)
            async function fetchData() {
              try {
                const res = await getProduct(el.id)
                // console.log(res)
                // setProducts(res)
                setProducts((prevState)=>{
                  // console.log(prevState)
                  return [...prevState, res]
                })
              } catch (err) {
                  console.log("err caught by component")
                  setError(()=>{throw new Error("blah")})
              }
            }
            fetchData();
          });
        }
      }, [brand]);
      // useEffect(() => {
      //   console.log(products)
      // },[products])
    // handler
    return(
        <div className={Styles.brands__section}>
            <div className={Styles.brands__section__container}>
                <h1 className={Styles.brands__section__container__h1}>{brand? brand.attributes.name: ""} </h1>
                <p className={Styles.brands__section__container__p}>{brand? brand.attributes.description : ""}</p>
                <div className={Styles.brands__section__container__grid}>
                {products?products.map((el, ind) => {
                  return (
                    <div className={Styles.brands__section__container__grid__item} key={`products_item_${ind}`}>
                        <Link to={`/products/${el.data.id}`}>
                        <div className={Styles.brands__section__container__grid__item__imgWrapper}>
                        <img className={Styles.brands__section__container__grid__item__img} src = {el.data.attributes.main_image.data.attributes.formats.small.url} alt = ""></img>
                        </div>
                      <div className={Styles.brands__section__container__grid__item__content}>
                      {el.data.attributes.title}
                      {/* {el.data.id} */}
                        </div>
                      </Link>
                    </div>  
                  );
                }):""}
                                      <Outlet />
                </div>
            </div>
        </div>
    )
}

export default ProductsList;
