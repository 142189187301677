// === packages
import { Fragment, useEffect, useState} from "react";
// import sgMail from "@sendgrid/mail"
// === modules
// === css
import Styles_about from './../css-modules/aboutPage.module.css'
// === images
import kinesimImg from './../../asset/images/aboutpage/DSC_1048_overlay.webp'
// === svg
// === handler


function AboutContent (props){
  // States
    const [error, setError] = useState(false)
    // Effects
    // handler

    return(
      <Fragment>
        <div className={Styles_about.about__section}>
          <div className={Styles_about.about__section__container}>
          <div className={Styles_about.about__section__container__grid2}>
          <div className={Styles_about.about__section__container__grid2__content}>
            <h3>Innovations for the community</h3>
            <p>An effective community rehabilitation practice is a combination of knowledge, experience, effective organization and passion. Our partners are experienced in the rehabilitation technology market with more than 10 years experience. We hope we can devote our knowledge and experience, and provide useful solutions for the users and patients in the community.</p>
            <p>Let us be your partner, and together we will bring joy and health to our patients in the community.</p>
          </div>
            <div className={Styles_about.about__section__container__grid2__imgWrapper}>
              <img src = {kinesimImg} alt = ""></img>
            </div>
          </div>
          <div className={Styles_about.about__section__container__grid3}>
            <div className={Styles_about.about__section__container__grid3__item}>
              <h3>Budget and space</h3>
              <p>The cost and productivity of our solutions are well balanced for  community and clinic settings. Innovations do not always have to be priced sky-high and out of reach.</p>
            </div>
            <div className={Styles_about.about__section__container__grid3__item}>
              <h3>Engagement</h3>
              <p>Clinical technologies often have poor engagement, low utilization rates and training intensity. Our solution engages with patients, especially elderly in fun and relatable ways. </p>
            </div>
            <div className={Styles_about.about__section__container__grid3__item}>
              <h3>Clinical relevance</h3>
              <p>Our solutions are fun, but they are not toys! Each solution will be robust in clinical relevance, and serve an productive role in any rehabilitation services.</p>
            </div>
          </div>
          <div className={Styles_about.about__section__container__grid4}></div>
          </div>
        </div>
      </Fragment>
    )
}

export default AboutContent;
