// === packages
import { Fragment, useEffect, useState} from "react";
// === modules
import Left from "./buttons/left";
import Right from "./buttons/right"; 
// === css
import Styles_products from './../css-modules/products.module.css'
// === svg
// === react-animations
import { bounce,fadeIn, fadeOut,fadeOutRight, fadeOutLeft} from 'react-animations';
import { StyleSheet, css } from 'aphrodite';
// === variables
const aniDuration = 500
const aniStyles = StyleSheet.create({
  bounce: {
    animationName: bounce,
    animationDuration: `${aniDuration}ms`
  },
  fadeIn: {
    animationName: fadeIn,
    animationDuration: `${aniDuration}ms`,
    animationFillMode: 'forwards'
  },
  fadeOut: {
    animationName: fadeOut,
    animationDuration: `${aniDuration}ms`,
    animationFillMode: 'forwards'
  },
  fadeOutRight: {
    animationName: fadeOutRight,
    animationDuration: `${aniDuration}ms`,
    animationFillMode: 'forwards'
  },
  fadeOutLeft: {
    animationName: fadeOutLeft,
    animationDuration: `${aniDuration}ms`,
    animationFillMode: 'forwards'
  }
})

function ProductGallery (props){
    const [error, setError] = useState(false)
    const [mainImg, setMainImg] = useState(false)
    const [gallery, setGallery] = useState(false)
    const [allImg, setAllImg] = useState(false)
    const [featuredImg, setFeaturedImg] = useState(0)
    const [featuredImgAni, setFeaturedImgAni] = useState(false)
    const [galleryOrder, setGalleryOrder] = useState(0)
    const [galleryImgAni, setGalleryImgAni] = useState(false)
    const [startX, setStartX] = useState(false)
    const [endX, setEndX] = useState(false)

    // Effects
    // load information props
    useEffect(()=>{
      if(props.product){
        setGallery(props.product.attributes.gallery.data)
        setMainImg(props.product.attributes.main_image.data.attributes.url)
      }
    },[props])
    // set all images url
    useEffect(()=>{
      if(mainImg && gallery){
        const galleryUrl = gallery.map((el)=>{return el.attributes.url})
        setAllImg([mainImg,...galleryUrl])
      }
    },[mainImg, gallery])
    // setGallery order
    useEffect(()=>{
      const initImg = (()=>{
        if(mainImg && gallery){
          const galleryUrl = gallery.map((el)=>{return el.attributes.url})
          return [mainImg,...galleryUrl]
        }
      })()
      if(galleryOrder >= 0 && allImg){
        let reArrangedImg = []
        for(let i = 0; i<allImg.length; i++){
          if(galleryOrder + i<= allImg.length -1){
            reArrangedImg.push(initImg[galleryOrder+i])
          }
          if(galleryOrder + i>allImg.length -1){
            reArrangedImg.push(initImg[galleryOrder+i-allImg.length])
          }
        }
        setAllImg(reArrangedImg)
      }
      
    },[galleryOrder])
    // swipe effect
    useEffect(()=>{
      if(endX && startX){
        // console.log(endX - startX)
        if(endX < startX - 50){
          mobileGalleryRHandler()
        }
        if(endX > startX + 50){
          mobileGalleryLHandler()
        }
      }
    },[endX])

    // handler
    // click on gallery images on desktop
    const galleryClickHandler = (ind)=>{
      setFeaturedImgAni(css(aniStyles.fadeOut))
      setTimeout(()=>{
        setFeaturedImg(allImg[ind])
        setFeaturedImgAni(css(aniStyles.fadeIn))
      }, aniDuration + 100)
    }
    // click on left/right on mobile
    const mobileGalleryLHandler = ()=>{
      if(galleryOrder > 0){
        setGalleryImgAni(css(aniStyles.fadeOutRight))
        setTimeout(()=>{
          setGalleryOrder((prev)=>{return prev - 1})
        },aniDuration)
        setTimeout(()=>{
          setGalleryImgAni(css(aniStyles.fadeIn))
        },aniDuration)
      }
    }
    const mobileGalleryRHandler = ()=>{
      if(galleryOrder < allImg.length -1 ){
        setGalleryImgAni(css(aniStyles.fadeOutLeft))
        setTimeout(()=>{
          setGalleryOrder((prev)=>{return prev + 1})
        },aniDuration)
        setTimeout(()=>{
          setGalleryImgAni(css(aniStyles.fadeIn))
        },aniDuration)
      }
    }
    // swipe handler on mobile
    const touchStartHandler = (e)=>{
      // e.preventDefault()
      setStartX(e.changedTouches[0].clientX)
    }
    const touchEndHandler = (e)=>{
      // e.preventDefault()
      setEndX(e.changedTouches[0].clientX)
    }
    return(
        <Fragment>
          <div className={Styles_products.product__grid__media__featured}>
            <div className={`${Styles_products.product__grid__media__featured__imgWrapper} ${featuredImgAni}`} >
              {mainImg?<img src = {featuredImg?featuredImg:mainImg} alt="" className={Styles_products.product__grid__media__featured__imgWrapper__img}></img>:""}
            </div>
          </div>
          <div className={Styles_products.LRHolder}>
            <Left class={Styles_products.LRHolder__L} leftHandler = {mobileGalleryLHandler}></Left>
            <Right class={Styles_products.LRHolder__R} rightHandler = {mobileGalleryRHandler}></Right>
          </div>

          <div onTouchStart={(e)=>{touchStartHandler(e)}}  onTouchEnd = {(e)=>{touchEndHandler(e)}}className={Styles_products.product__grid__media__gallery}>
          {allImg?allImg.map((el, ind)=>{
            return (
              <div className={`${Styles_products.product__grid__media__gallery__imgWrapper} ${galleryImgAni}`} key = {`gallery--item--${ind}`}>
            <img onClick={()=>{galleryClickHandler(ind)}} className={Styles_products.product__grid__media__gallery__imgWrapper__img} src = {el?el:""} alt = ""  ind = {ind}></img>
            </div>
            )
          }):""}
          </div>
          <div className={Styles_products.pagHolder}>
            {allImg?allImg.map((el, ind)=>{
              const highlightInd = galleryOrder?galleryOrder:0
              if(ind === highlightInd){
                return (
                  <div key = {`galleryImg__${ind}`} className={Styles_products.pagHolder__pags__active}></div>
                )
              }else
              return(
                <div key = {`galleryImg__${ind}`} className={Styles_products.pagHolder__pags}></div>
              )
            }):""}
          </div>
        </Fragment>
    )
}

export default ProductGallery;
