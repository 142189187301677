// === packages
import { Fragment, useEffect, useState, useRef} from "react";
import emailjs from '@emailjs/browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope, faAddressBook } from '@fortawesome/free-solid-svg-icons'

// === modules
// === css
import Styles_contact from './../css-modules/contactPage.module.css'
// === svg
// === handler
// === font awesome


// const iconAddress = <FontAwesomeIcon icon="fa-solid fa-address-book" />

function ContactForm (props){
  // states
  const [emailOK, setEmailOK] = useState(false)
  const [error, setError] = useState(false)

  // effects
  useEffect(()=>{
    if(emailOK && emailOK === "OK"){
      console.log("yay email ok")
    }
  },[emailOK])
  
  // handler
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm(process.env.REACT_APP_YOUR_SERVICE_ID, process.env.REACT_APP_YOUR_TEMPLATE_ID, form.current, process.env.REACT_APP_YOUR_PUBLIC_KEY)
      .then((result) => {
          console.log(result.text);
          setEmailOK("OK")
      }, (error) => {
          console.log(error.text);
          setEmailOK("ERROR")
          setError(error.text)
      });
  };

  return (
    (()=>{
      if(emailOK&&emailOK === "OK"){
        return(
          <div>Email ok.</div>
        )
      }
      if(emailOK&&emailOK === "ERROR"){
        return(
          <div>Something went wrong. Please send an email to yance@sankhz.com for support.</div>
        )
      }
      else{
        return (
          <div className={Styles_contact.contact__section}>
            <div className={Styles_contact.contact__section__container}>
              <div className={Styles_contact.contact__section__container__grid}>
                <div
                  className={Styles_contact.contact__section__container__grid__side}>
                  <ul >
                    <li >
                      <FontAwesomeIcon icon={faEnvelope} />
                      <span >
                        sales@sankhz.com
                      </span>
                    </li>
                    <li >
                      <FontAwesomeIcon icon={faPhone} />
                      <span >
                        +65 8876 2060
                      </span>
                    </li>
                    <li >
                      <FontAwesomeIcon icon={faAddressBook} />
                      <span >
                        SANKHZ Pte Ltd #13-76, Wood Square Tower 1, 12 Woodlands Square, Singapore 737715
                      </span>
                    </li>
                  </ul>
                </div>

                <form ref={form} onSubmit={sendEmail}>
                  <label>Name</label>
                  <input type="text" name="user_name" />
                  <label>Company/ Institution</label>
                  <input type="text" name="user_company" />
                  <label>Interest</label>
                  <input type="text" name="user_interest" />
                  <label>Email</label>
                  <input type="email" name="user_email" />
                  <label>Message</label>
                  <textarea name="message" />
                  <input type="submit" value="Send" />
                </form>
              </div>
            </div>
          </div>
        );
      }
    })()
  );
}

export default ContactForm;
