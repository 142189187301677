// === packages
import { Fragment, useEffect, useState} from "react";
import { useParams } from "react-router-dom";
// === css modules
import Styles from "./../components/css-modules/brands.module.css"
import Styles_products from "./../components/css-modules/products.module.css"
// === components
import Body from '../components/sections/body'
import ProductGallery from "../components/layout/productGallery";
import ProductContent from "../components/layout/productContent";
// === handler
import getProduct from "../apiCalls/getProduct";

function ProductPage (props){
    // params
    let params = useParams();
    // states
    const [product, setProduct] = useState(false)
    const [error, setError] = useState(false)

    // effect
    useEffect(()=>{
        // console.log(params.productId)
        async function fetchData() {
            try {
              const res = await getProduct(params.productId)
              setProduct(res.data)
            //   console.log(res)
            } catch (err) {
                console.log("err caught by component")
                setError(()=>{throw new Error("blah")})
            }
          }
          fetchData();
    },[])
    // handlers
    
    return (
        <Fragment>
        <Body color = '#fff'>
            <div className={Styles.brands__section}>
            <div className={Styles.brands__section__container}>
                <h1 className={Styles.brands__section__container__h1}>{product?product.attributes.title:""}</h1>
                <div className={Styles_products.product__grid}>
                    <div className={Styles_products.product__grid__media}>
                        <ProductGallery product = {product?product:""}></ProductGallery>
                    </div>
                    <div className={Styles_products.product__grid__content}>
                        <ProductContent product = {product?product:""}></ProductContent>
                    </div>
                </div>
                </div>
                </div>
        </Body>
        </Fragment>
    )
}

export default ProductPage;