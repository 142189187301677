// === css
import styles from './../../css-modules/buttons.module.css'

function SubmitBtn(props){
    // === handler
    return (
        <a href = {props.href}>
            <div className={styles.btnGen}>{props.children}</div>
        </a>       
    )
}

export default SubmitBtn