// === packages
import React from 'react';
// === handlers
// === modules
import IconFB from './../../asset/icons/facebook'
import IconLI from './../../asset/icons/linkedIn'
import IconYT from './../../asset/icons/youTube'
// === css 
import styles from "./../css-modules/sections.module.css"
// === css 

function FooterSocial(props){
    
    return (
      <React.Fragment>
            <div className={styles.site_footer_container_grid_link}>
            <IconFB style = {{width: "100px"}}href = "https://www.facebook.com/Sankhz-106523834994239"/>
            </div>
            <div className={styles.site_footer_container_grid_link}>
            <IconLI href = "https://www.youtube.com/playlist?list=PLrWVVG8vG3meriIlIStO0LKBDyZ9B9wBf"/>
            </div>
            <div className={styles.site_footer_container_grid_link}>
            <IconYT href = "https://www.linkedin.com/company/sankhz/?viewAsMember=true"/>
            </div>
      </React.Fragment>
    );
}

export default FooterSocial;