// === packages
import { useEffect, useState} from "react";
import {Outlet,Link} from "react-router-dom";


// === css
import Styles from './../css-modules/brands.module.css'
// === handler
import getBrands from '../../apiCalls/getBrands';

function Brands (props){
    const [error, setError] = useState(false)
    const [brands, setBrands] = useState([])
    // Effects
    useEffect(() => {
        async function fetchData() {
          try {
            const brands = await getBrands("?populate=*")
            // console.log(brands)
            setBrands(brands.data)
          } catch (err) {
              console.log("err caught by component")
              console.log(err)
              setError(()=>{throw new Error("blah")})
          }
        }
        fetchData();
      }, []);
    // handler
    return(
        <div className={Styles.brands__section}>
            <div className={Styles.brands__section__container}>
                <h1 className={Styles.brands__section__container__h1}>Brands</h1>
                <p className={Styles.brands__section__container__p}>We source all over the world to find the best solutions trusted by major clinics and hospitals all over Europe and Asia. Contact us and get the full specifications, articles and reference list.</p>
                <div className={Styles.brands__section__container__grid}>
                {brands.map((el, ind) => {
                  return (
                    <div className={Styles.brands__section__container__grid__item} key={`brands_item_${ind}`}>
                        {/* <Link to={`/brands/${el.attributes.brand_Id}`}> */}
                        <Link to={`/brands/${el.id}`}>
                        <div className={Styles.brands__section__container__grid__item__imgWrapper}>
                        <img style = {{height:"auto"}} className={Styles.brands__section__container__grid__item__img} src = {el.attributes.logo.data?.attributes.formats.thumbnail.url?el.attributes.logo.data.attributes.formats.thumbnail.url:""} alt = {`brandImage_${el.attributes.name}`}></img>
                        </div>
                      <div className={Styles.brands__section__container__grid__item__content}>
                      {el.attributes.name?el.attributes.name:""} ({el.attributes.products.data?.length?el.attributes.products.data.length:""})
                        </div>
                      </Link>
                    </div>  
                  );
                })}
                                      <Outlet />
                </div>
            </div>
        </div>
    )
}

export default Brands;
