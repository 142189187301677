import React from 'react';
import {useState, useEffect} from 'react'
// === handlers
import getBrands from '../../apiCalls/getBrands';
// === css 
import styles from "./../css-modules/sections.module.css"

function FooterBrands(props){
    const [error, setError] = useState(false)
    const [brands, setBrands] = useState([])
    useEffect(() => {
        async function fetchData() {
          try {
            const brands = await getBrands()
            // console.log(brands)
            setBrands(brands.data)
          } catch (err) {
              console.log("err caught by component")
              setError(()=>{throw new Error("blah")})
          }
        }
        fetchData();
      }, []);
    return (
      <React.Fragment>
            {brands.map((el, ind) => {
                  return (
                    <div className={styles.site_footer_container_grid_link} key={`footer_brands_${ind}`}>
                      <a href = {`/brands/${el.id}`}>{el.attributes.name}</a>
                    </div>
                  );
                })}
      </React.Fragment>
    );
}

export default FooterBrands;