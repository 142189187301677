const defaultUrl = "https://backendstrapi-dot-strapi-sankhz.as.r.appspot.com/api/brands"
// const defaultUrl = "https://strapi-sankhz.as.r.appspot.com/api/brands?populate=*"

const getBrands = async function(param=""){
  try{
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    
    const res = await fetch(`${defaultUrl}${param}`, requestOptions)
    const result = await res.text()
    const data = await JSON.parse(result)
    return data
  }catch(err){console.log(err)}

}

export default getBrands
