// === packages
import React from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {Helmet} from "react-helmet";


// === components
import Header from './components/sections/header'
import Footer from './components/sections/footer';
import MainFrame from './components/sections/mainFrame';
import ErrorBoundary from './components/functional/errorBoundary';


// === pages
import HomePage from './pages/homePage';
import BrandsPage from './pages/brandsPage'
import TimeLinePage from './pages/timeLinePage';
import NoSuchPage from './pages/404Page';
import AboutPage from './pages/aboutPage';
import BrandPage from './pages/brandPage';
import ProductPage from './pages/productPage';
import ContactPage from './pages/contactPage';

// === images
// === variables

function App() {
  return (
      <BrowserRouter>
    <MainFrame>
      <Helmet>
                <meta charSet="utf-8" />
                <title>SANKHZ | Key Of Life </title>
                <meta name="theme-color" content="#f4f0eb" />
                <meta property="og:title" content="SANKHZ | Key Of Life" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.sankhz.com/" />
                <meta property="og:image" content="https://storage.googleapis.com/sankhz--strapi/sankhz_logo_c52ffeb6fa/sankhz_logo_c52ffeb6fa.png" />
                            </Helmet>
      <Header></Header>
      <ErrorBoundary>
        <Routes>
            <Route index element={<HomePage />} />
            <Route path ="about" element={<AboutPage />}/>
            <Route path ="brands" element={<BrandsPage />}/>
            <Route path ="brands/:brandId" element={<BrandPage />}/>
            <Route path ="contact" element={<ContactPage />}/>
            <Route path ="products/:productId" element={<ProductPage />}/>
            <Route path ="timeline" element={<TimeLinePage />} />
            {/* <Route path = "lovedones" element={<CloseRelativesPage />} /> */}
            <Route path = "*" element={<NoSuchPage />} />
        </Routes>
        </ErrorBoundary>
      
      <Footer></Footer>
    </MainFrame>
      </BrowserRouter>
  );
}

export default App;
