// === packages
import { Fragment, useEffect, useState} from "react";
// === modules
import IconFB from './../../asset/icons/facebook'
import IconLI from './../../asset/icons/linkedIn'
import IconYT from './../../asset/icons/youTube'
// === css
import Styles_about from './../css-modules/aboutPage.module.css'
// === images
// === svg
// === handler



function Social (props){
  // States
    const [error, setError] = useState(false)
    // Effects
    // handler

    return(
        <div className={Styles_about.social__container}>
          <h3>Connect with us</h3>
        <div className={Styles_about.social__container__grid3}>
        <div className={Styles_about.social__container__grid3__item}>
          <IconFB href = "https://www.facebook.com/Sankhz-106523834994239"/>
        </div>
        <div className={Styles_about.social__container__grid3__item}>
          <IconLI href = "https://www.youtube.com/playlist?list=PLrWVVG8vG3meriIlIStO0LKBDyZ9B9wBf"/>
        </div>
        <div className={Styles_about.social__container__grid3__item}>
          <IconYT href = "https://www.linkedin.com/company/sankhz/?viewAsMember=true"/>
        </div>
        </div>
        </div>
    )
}

export default Social;
